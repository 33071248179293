@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&family=Quicksand:wght@300&display=swap');
.navbar {
  width: 100%;
  height: 110px;
  background-color: rgb(51, 20, 20, 65%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  letter-spacing: .2rem;
}

.title{
  font-size:xx-large;
  color: white;
  padding-left: 100px;
  padding-right: 100px;
}

.title a {
  text-decoration: none;
  cursor: pointer;
  color: white;
}

.links{
  font-family: 'Quicksand', sans-serif;
  display: flex;
  align-items: center;
}

.links a {
  color: #b3a6a7;
  text-decoration: none;
  margin-left: 20px;
  margin-right: 20px;
}

@media only screen and (max-width: 1600px) {
  .title {
    font-size: x-large;
    padding-left: 50px;
    padding-right: 50px;
  }
  .navbar {
    height: 80px;
  }
  .links a {
    font-size: large;
  }
}

@media only screen and (max-width: 1300px) {
  .title {
    font-size: large;
    padding-left: 50px;
    padding-right: 50px;
  }
  .links a {
    font-size: small;
    margin-right: 10px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 1080px) {
  .title {
    font-size: small;
    padding-left: 30px;
    padding-right: 30px;
  }
  .navbar {
    height: 60px;
  }
  .links a {
    font-size: x-small;
    margin-right: 10px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 800px) {
  .title {
    font-size: 10px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .navbar {
    height: 40px;
    letter-spacing: .1rem;
  }
  .links a {
    font-size: 7px;
    margin-right: 10px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .title {
    font-size: 7px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .navbar {
    height: 30px;
  }
  .links a {
    font-size: 5px;
    margin-right: 5px;
    margin-left: 5px;
  }
}
