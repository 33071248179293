@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400&family=Quicksand:wght@300&display=swap');


.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: 'Oswald', sans-serif;

}

.social-container {
  background: #000000;
  padding: 10px 30px;
}

body {
  padding: 0;
  margin: 0;
  background-color: rgb(10, 5, 2);
  letter-spacing: .1rem;
  color:white;
  font-size:x-large;
}

@media only screen and (max-width: 1000px) {
  body {
    letter-spacing: .05rem;
  }
}