.forgot-password {
	max-width: 600px;
	width: 100%;
}
.forgot-password input {
	background-color: #fff;
	border: none;
	border-radius: 5px;
	font-size: 18px;
	padding: 20px 20px;
	width: calc(100% - 40px);
}

.forgot-pass-label {
	margin-top: 50px;
	font-size: 30px;
	margin-bottom: 10px;
}

.forgot-pass-button {
	display: flex;
	margin-bottom: 50px;
}

.forgot-pass-input {
	margin-bottom: 20px;
}

@media only screen and (max-width: 1600px) {
	.forgot-pass-label {
		font-size: 25px;
	}
	.forgot-pass-message {
		font-size: 20px;
	}
}

@media only screen and (max-width: 1500px) {
	.forgot-pass-label {
		font-size: 20px;
		margin-top: 40px;
	}
	.forgot-pass-message {
		font-size: 17px;
	}
	.forgot-pass-button {
		margin-bottom: 40px;
	}
}

@media only screen and (max-width: 1200px) {
	.forgot-pass-label {
		font-size: 18px;
	}
	.forgot-pass-message {
		font-size: 15px;
	}
}

@media only screen and (max-width: 1105px) {
	.forgot-pass-label {
		font-size: 16px;
		margin-top: 30px;
	}
	.forgot-pass-button {
		margin-bottom: 30px;
	}
	.forgot-pass-message {
		font-size: 13px;
	}
}

@media only screen and (max-width: 900px) {
	.forgot-pass-label {
		font-size: 14px;
	}
	.forgot-pass-message {
		font-size: 11px;
	}
}

@media only screen and (max-width: 800px) {
	.forgot-pass-label {
		font-size: 11px;
		margin-top: 20px;
	}
	.forgot-pass-input {
		margin-bottom: 10px;
	}
	.forgot-pass-button {
		margin-bottom: 20px;
	}
	.forgot-pass-message {
		font-size: 9px;
	}
}

@media only screen and (max-width: 800px) {
	.forgot-pass-label {
		font-size: 9px;
		margin-bottom: 5px;
		margin-top: 15px;
	}
	.forgot-pass-message {
		font-size: 7px;
	}
	.forgot-pass-button {
		margin-bottom: 10px;
	}
}
