.popup{
    position:fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.popup-inner{
    position:relative;
    padding: 32px;
    width: 100%;
}

.popup-inner-upcomingAdd, .popup-inner-upcomingAdd .loading {
    position:relative;
    background-color: #331414;
    overflow: auto;
    max-height: 800px;
}

.loading {
    width: 700px;
}

.close-btn {
    display: flex;
}

.popup-inner-upcomingAdd .close-btn{
    position: absolute;
    top:10px;
    right:10px;
    background-color: transparent;
    border: none;
    outline:none;
    color:white;
    cursor: pointer;
}

.popup-inner .close-btn{
    position: absolute;
    top:16px;
    right:16px;
    background-color: transparent;
    border: none;
    outline:none;
    color:white;
    margin-right: 25px;
    margin-top: -25px;
    cursor: pointer;
}

.popup-wrapper{
    position: relative;
    padding-top:31%
}

.popup-player{
    position: absolute;
    top: 0;
    left: 0;
}

@media only screen and (max-width: 1600px) {
    .popup-inner-upcomingAdd {
        max-height: 600px;
    }
}

@media only screen and (max-width: 1300px) {
    .popup-inner-upcomingAdd {
        max-height: 500px;
    }
    .loading {
        width: 600px;
    }
}

@media only screen and (max-width: 1105px) {
    .popup-inner-upcomingAdd {
        max-height: 400px;
    }
    .loading {
        width: 500px;
    }
}

@media only screen and (max-width: 900px) {
    .popup-inner-upcomingAdd {
        max-height: 300px;
    }
    .loading {
        width: 500px;
    }
}

@media only screen and (max-width: 800px) {
    .popup-inner-upcomingAdd {
        max-height: 250px;
    }
    .loading {
        width: 350px;
    }
    .popup-inner-upcomingAdd .close-btn {
        top: 5px;
        right: 5px
    }
}

@media only screen and (max-width: 500px) {
    .popup-inner-upcomingAdd {
        max-height: 200px;
    }
    .loading {
        width: 250px;
    }
    .popup-inner-upcomingAdd .close-btn {
        top: 5px;
    }
}
