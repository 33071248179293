.play{
    display: flex;
    flex-direction: column;
    align-items: center;
   
}
.playImg{
    height: 310px;
    width: 360px;
    object-fit: cover;
}
.playTitle{
    font-weight: lighter;
}

.playDuration{
    font-weight: lighter;
    font-size: small;
    margin-top: 3px
}
.openDetails{
    background-color: transparent;
    border: none;
    outline:none;
}

@media only screen and (max-width: 1900px) {
    .playImg {
        height: 320px;
        width: 376px;
    }
}

@media only screen and (max-width: 1600px) {
    .playImg {
        height: 260px;
        width: 316px;
    }
    .playTitle {
        font-size: 20px;
    }
}

@media only screen and (max-width: 1500px) {
    .playImg {
        height: 224px;
        width: 280px;
    }
}

@media only screen and (max-width: 1300px) {
    .playImg {
        height: 204px;
        width: 251px;
    }
    .playTitle {
        font-size: 16px;
    }
    .playDuration {
        font-size: x-small;
    }
}

@media only screen and (max-width: 1200px) {
    .playImg {
        height: 184px;
        width: 227px;
    }
}

@media only screen and (max-width: 1105px) {
    .playImg {
        height: 164px;
        width: 198px;
    }
    .playTitle {
        font-size: 14px;
    }
    .playDuration {
        font-size: xx-small;
    }
}

@media only screen and (max-width: 1000px) {
    .playImg {
        height: 144px;
        width: 172px;   
    }
    .playTitle {
        font-size: 12px;
    }
    .playDuration {
        font-size: 8px;
    }
}

@media only screen and (max-width: 900px) {
    .playImg {
        height: 134px;
        width: 163px;   
    }
}

@media only screen and (max-width: 800px) {
    .playImg {
        height: 104px;
        width: 141px;   
    }
    .playTitle {
        font-size: 10px;
    }
    .playDuration {
        font-size: 6px;
    }
}

@media only screen and (max-width: 720px) {
    .playImg {
        height: 114px;
        width: 138px;   
    }
}

@media only screen and (max-width: 600px) {
    .playImg {
        height: 117px;
        width: 142px;   
    }
}

@media only screen and (max-width: 500px) {
    .playImg {
        height: 100px;
        width: 117px;   
    }
    .playTitle {
        font-size: 8px;
    }
    .playDuration {
        font-size: 5px;
    }
}

@media only screen and (max-width: 420px) {
    .playImg {
        height: 84px;
        width: 96px;   
    }
    .playTitle {
        font-size: 7px;
    }
    .playDuration {
        font-size: 4px;
    }
}

@media only screen and (max-width: 400px) {
    .playImg {
        height: 74px;
        width: 86px;   
    }
}