@import url('https://fonts.googleapis.com/css?family=Abel%27'); 

.admin{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100vh;

}
.admin_content{
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
}
.admin_title{
    justify-content: flex-end;
    display: flex;
    margin-bottom: 10px;
    margin-right: 50px;
    font-size: 50px;
    font-family: Abel;
}

.admin_form{
    border-bottom: 3px solid #fff;
    border-top: 3px solid #fff;
    display: flex;
    flex-direction: column;
}
.admin_input{
    width: calc(100% - 40px);
    padding:20px 20px;
    background-color: #fff;
    border:none;
    font-size: 18px;
    border-radius: 5px;
}
.admin_input-email{
    margin-bottom: 10px;
    margin-top: 50px;
}
.admin_input-password{
    margin-bottom: 20px;
}

.admin_form-actions{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}

.btn{
    padding:20px 30px;
    transition: all 0.4s;
}

.btn-submit{
    border:1px solid #fff;
    background-color: #000;
}
.btn-submit:hover{
    background-color: #222;
}

.admin_form-actions a {
    color: white;
    text-decoration: none;
}

.admin_form-actions a:hover {
    text-decoration: underline;
}

.admin-incorrect {
    margin-top: 10px;
}

@media only screen and (max-width: 1900px) {
    .admin_input {
        font-size: 17px;
    }
    .admin_form-actions a {
        font-size: 22px;
    }
}

@media only screen and (max-width: 1600px) {
    .admin_input {
        font-size: 15px;
    }
    .admin_form-actions a {
        font-size: 20px;
    }
    .admin-incorrect {
        font-size: 22px;
    }
}

@media only screen and (max-width: 1600px) {
    .admin_title {
        font-size: 38px;
    }
    .admin_input {
        font-size: 13px;
    }
    .admin_input-email {
        margin-top: 40px;
    }
    .admin_form-actions a {
        font-size: 18px;
    }
    .admin_form-actions {
        margin-bottom: 40px;
    }
    .admin_content {
        max-width: 500px;
    }
}

@media only screen and (max-width: 1300px) {
    .admin_title {
        font-size: 32px;
        margin-right: 40px;
    }
    .admin-incorrect {
        font-size: 18px;
    }
    .admin_input {
        font-size: 12px;
    }
    .admin_form-actions a {
        font-size: 16px;
    }
    .admin_content {
        max-width: 450px;
    }
}

@media only screen and (max-width: 1200px) {
    .admin-incorrect {
        font-size: 17px;
    }
    .admin_form-actions a {
        font-size: 15px;
    }
    .admin_input {
        font-size: 11px;
    }
    .admin_input-email {
        margin-top: 30px;
    }
    .admin_form-actions {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 1105px) {
    .admin_title {
        font-size: 30px;
    }
    .admin-incorrect {
        font-size: 15px;
    }
    .admin_input {
        font-size: 11px;
        padding: 15px;
        width: calc(100% - 30px);
    }
    .admin_form-actions a {
        font-size: 14px;
    }
    .admin_content {
        max-width: 400px;
    }
}

@media only screen and (max-width: 1000px) {
    .admin_title {
        font-size: 28px;
    }
    .admin-incorrect {
        font-size: 14px;
    }
    .admin_input {
        font-size: 10px;
        padding: 12px;
        width: calc(100% - 24px);
    }
    .admin_form-actions a {
        font-size: 13px;
    }
    .admin_content {
        max-width: 360px;
    }
    .admin_input-password {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 900px) {
    .admin_title {
        font-size: 26px;
    }
    .admin-incorrect {
        font-size: 12px;
    }
    .admin_input {
        font-size: 9px;
    }
    .admin_form-actions a {
        font-size: 11px;
    }
    .admin_content {
        max-width: 340px;
    }
    .admin {
        height: 115vh;
    }
}

@media only screen and (max-width: 800px) {
    .admin_title {
        font-size: 22px;
        margin-right: 30px;
    }
    .admin_input {
        font-size: 7px;
        padding: 10px;
        width: calc(100% - 20px);
    }
    .admin-incorrect {
        font-size: 11px;
    }
    .admin_form-actions a {
        font-size: 10px;
    }
    .admin_input-email {
        margin-top: 20px;
    }
    .admin_form-actions {
        margin-bottom: 20px;
    }
    .admin_content {
        max-width: 300px;
    }
}

@media only screen and (max-width: 600px) {
    .admin_title {
        font-size: 18px;
    }
    .admin-incorrect {
        font-size: 9px;
    }
    .admin_input-email {
        margin-top: 15px;
        margin-bottom: 5px;
    }
    .admin_input {
        font-size: 5px;
        padding: 7px;
        width: calc(100% - 14px);
    }
    .admin_form-actions a {
        font-size: 8px;
    }
    .admin_form-actions {
        margin-bottom: 10px;
        align-items: first baseline;
    }
    .admin_content {
        max-width: 250px;
    }
}

@media only screen and (max-width: 600px) {
    .admin {
        height: 93vh;
    }
}

