.password-reset {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.reset-password_notmatched {
	color: rgba(255, 0, 0, 0.758);
	margin-bottom: 20px;
}

.newPass {
	margin-top: 50px;
	margin-bottom: 10px;
}

.confirm-pass {
	margin-bottom: 20px;
}

.changed-pass {
	color: #fff;
	font-size: 25px;
	font-weight: 300;
	margin: 0;
	text-align: center;
	margin-bottom: 20px;
}

@media only screen and (max-width: 1600px) {
	.reset-password_notmatched, .changed-pass {
		font-size: 20px;
	}
}

@media only screen and (max-width: 1300px) {
	.newPass {
		margin-top: 40px;
	}
	.forgot-pass-button {
		margin-bottom: 30px;
	}
	.reset-password_notmatched, .changed-pass {
		font-size: 17px;
	}
}

@media only screen and (max-width: 1105px) {
	.reset-password_notmatched, .changed-pass {
		font-size: 14px;
	}
}

@media only screen and (max-width: 900px) {
	.newPass {
		margin-top: 30px;
	}
	.confirm-pass {
		margin-bottom: 10px;
	}
	.forgot-pass-button {
		margin-bottom: 20px;
	}
	.reset-password_notmatched, .changed-pass {
		font-size: 12px;
	}
}

@media only screen and (max-width: 800px) {
	.reset-password_notmatched, .changed-pass {
		margin-bottom: 10px;
		font-size: 10px;
	}
}

@media only screen and (max-width: 600px) {
	.newPass {
		margin-top: 20px;
		margin-bottom: 5px;
	}
	.forgot-pass-button {
		margin-bottom: 5px;
	}
	.reset-password_notmatched, .changed-pass {
		font-size: 8px;
	}
}